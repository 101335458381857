import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button, Tabs, Typography, ConfigProvider, Spin } from "antd";
import ProductsDashboard from "./pages/ProductsDashboard";
import AIAutoFillDashboard from "./pages/AIAutoFillDashboard";
import Search from "./pages/Search";
import CategoryTree from "./pages/CategoryTree.jsx";
import ReportsDashboard from "./pages/ReportsDashboard.jsx";
import { useAuth } from "../context/AuthContext.js";
import styled from "styled-components";
import CharacterHint from "./CharacterHint"; // Import the CharacterHint component

import { getCategoriesTree, searchProductsApi, getAllProducts, getUniqueBrands, getUniqueCategories, getUniqueManufacturers } from "../services";
import "../styles/Dashboard.css"; // Import custom CSS for styling

const { Header, Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  const [refreshKey, setRefreshKey] = useState(0); // Initial key
  const [activeTabKey, setActiveTabKey] = useState("1"); // Track the active tab key
  const [isLoading, setIsLoading] = useState(true); // Loading state for initial data fetch

  const { logOut } = useAuth();

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(false)
      try {

        // Caching puprposes
        searchProductsApi({ text: "a", category: "", manufacturer: "", brand: "", tags: [] });
        getUniqueCategories();
        getUniqueManufacturers();
        getUniqueBrands();
        getCategoriesTree()

      } catch (e) {
        console.error(e);
      } finally {
      }
    };

    fetchInitialData();
  }, []);

  const items = [
    {
      key: "1",
      label: "Overview",
      children: <ReportsDashboard key={refreshKey} />,
    },
    {
      key: "2",
      label: "Badayli",
      children: <ProductsDashboard key={refreshKey} />,
    },
    {
      key: "3",
      label: "Search",
      children: <Search key={refreshKey} />,
    },
    {
      key: "4",
      label: "Categories Tree",
      children: <CategoryTree key={refreshKey} />,
    },
    {
      key: "5",
      label: "AI Auto Fill",
      children: <AIAutoFillDashboard key={refreshKey} />,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#23AC68', // green
          colorSecondary: '#ED4E44', // red
          colorAccent: '#414241', // dark gray
          borderRadius: '8px',
          fontFamily: "'Roboto', sans-serif",
        },
      }}
    >
      <Layout style={{ minHeight: "100vh", backgroundColor: '#F4F7F6' }}>
        <Header style={{ background: "#414241", padding: "0 24px", boxShadow: '0 2px 8px #A6B6AA' }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Row align="middle">
                <img src="/assets/logo.svg" alt="App Logo" style={{ height: '40px', marginRight: '10px' }} />
                <Title level={4} style={{ margin: 0, color: '#F4F7F6' }}>
                  DATA ASSISTANT
                </Title>
              </Row>
            </Col>
            <Col>
              <Button type="primary" onClick={handleRefresh} style={{ marginRight: 10 }}>
                Refresh
              </Button>
              <Button type="default" onClick={logOut}>
                Log Out
              </Button>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: "24px 50px", backgroundColor: '#F4F7F6' }}>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Tabs
                defaultActiveKey="1"
                items={items}
                destroyInactiveTabPane
                onChange={(key) => setActiveTabKey(key)}
              />
              <CharacterHint activeTabKey={activeTabKey} /> {/* Pass the active tab key */}
            </>
          )}
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default Dashboard;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
  .ant-tabs-tab {
    padding: 10px 20px;
    font-weight: bold;
    color: #414241;
    border-bottom: 2px solid transparent;
    transition: all 0.3s;
    &:not(:last-child)::after {
      content: '|';
      color: #23AC68;
      padding: 0 10px;
    }
    &:hover {
      color: #23AC68;
    }
    &.ant-tabs-tab-active {
      color: #23AC68;
      border-bottom: 2px solid #23AC68;
    }
  }
  .ant-tabs-tab-btn {
    color: inherit;
  }
  .ant-tabs-ink-bar {
    background-color: #23AC68;
  }
  .ant-tabs-content-holder {
    padding: 24px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #ffffff;
  }
`;
