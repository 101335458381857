import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag,
  Spin,
  Typography,
  Layout,
} from "antd";
import { Pie, Bar } from "react-chartjs-2";
import { getMostSimilarProductsApi, searchProductsApi } from "../../services";
import "chart.js/auto";

const { Title } = Typography;
const { Content } = Layout;

const ProductsDashboard = ({ refreshKey }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [mostSimilarProducts, setMostSimilarProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchText, setSearchText] = useState('');

  const options = products.map((product) => {
    return {
      key: product.id,
      value: product.id,
      label: `${product.name}`,
      originalProduct: { ...product },
    };
  });

  async function getSimilarProducts(product) {
    setIsLoading(true);
    if (product) {
      const mostSimilarProducts = await getMostSimilarProductsApi({
        productKey: product.key,
      });
      setMostSimilarProducts(mostSimilarProducts);
    }
    setIsLoading(false);
  }

  const fetchProducts = useCallback(
    async (text) => {
      setIsLoading(true);
      const products = await searchProductsApi({ text, category: "", manufacturer: "", brand: "", tags: [] });
      setProducts(products);
      if (products.length > 0) {
        const firstProduct = products[0];
        setSelectedProduct(firstProduct);
        getSimilarProducts(firstProduct);
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    fetchProducts("a");
  }, [fetchProducts]);

  useEffect(() => {
    if (selectedProduct) {
      getSimilarProducts(selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (refreshKey) {
      getSimilarProducts(selectedProduct);
    }
  }, [refreshKey]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  const handleSearch = useCallback(
    (value) => {
      setSearchText(value);
      fetchProducts(value);
    },
    [fetchProducts]
  );

  const similarityRanges = {
    '90+': 0,
    '80-90': 0,
    '70-80': 0,
    '60-70': 0,
    '50-60': 0,
    'Below 50': 0,
  };

  mostSimilarProducts.forEach((product) => {
    if (product.similarity >= 90) {
      similarityRanges['90+'] += 1;
    } else if (product.similarity >= 80) {
      similarityRanges['80-90'] += 1;
    } else if (product.similarity >= 70) {
      similarityRanges['70-80'] += 1;
    } else if (product.similarity >= 60) {
      similarityRanges['60-70'] += 1;
    } else if (product.similarity >= 50) {
      similarityRanges['50-60'] += 1;
    } else {
      similarityRanges['Below 50'] += 1;
    }
  });

  const chartData = {
    labels: Object.keys(similarityRanges),
    datasets: [
      {
        label: "Number of Products",
        data: Object.values(similarityRanges),
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 99, 132, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Content style={{ padding: "24px 50px", backgroundColor: "#F4F7F6" }}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Card>
            <Form layout="vertical">
              <Form.Item label="Product">
                <Select
                  options={options}
                  onChange={(value, option) => {
                    setSelectedProduct(option.originalProduct);
                  }}
                  onSearch={handleSearch}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={false} // Disable default filtering to use custom search
                  loading={isLoading}
                  value={selectedProduct ? selectedProduct.id : undefined}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {isLoading ? (
        <Spin />
      ) : !selectedProduct ? (
        <>
          <div>Please select a product to view details.</div>
        </>
      ) : (
        <>
          <Row gutter={[32, 32]}>
            <Col span={6}>
              <div>
                <Title level={5}>Category Key</Title>
                <Card>
                  <div>{selectedProduct.category_key}</div>
                </Card>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <Title level={5}># Badayels (distance 80+)</Title>
                <Card>
                  <div>{selectedProduct.badayel_products_count || 0}</div>
                </Card>
              </div>
            </Col>

            <Col span={12}>
              <div>
                <Title level={5}>Tags</Title>
                <Card>
                  <div>
                    {selectedProduct.tags.map((tag) => (
                      <Tag style={{ margin: 4 }} key={tag.id}>
                        {tag.name}
                      </Tag>
                    ))}
                  </div>
                </Card>
              </div>
            </Col>
            <Col span={12}>
              <Title level={5}>Badayel Similarity Metrics (Pie Chart)</Title>
              <Card>
                <Pie data={chartData} options={{ maintainAspectRatio: false }} height={300} />
              </Card>
            </Col>
            <Col span={12}>
              <Title level={5}>Badayel Similarity Metrics (Bar Chart)</Title>
              <Card>
                <Bar data={chartData} options={{ maintainAspectRatio: false }} height={300} />
              </Card>
            </Col>
            <Col span={24}>
              <Title level={5}>Badayel</Title>
              <Card>
                <Table
                  pagination={{ pageSize: 1000 }}
                  className="custom-table"
                  columns={[
                    {
                      title: "Product Name",
                      dataIndex: ["similar_product", "name"],
                      key: "product_name",
                    },
                    {
                      title: "Distance",
                      dataIndex: "similarity",
                      key: "similarity",
                      sorter: (a, b) => a.similarity - b.similarity,
                    },
                    {
                      title: "Name Distance",
                      dataIndex: "similarity_name",
                      key: "similarity_name",
                      sorter: (a, b) => a.similarity_name - b.similarity_name,
                    },
                    {
                      title: "Category Distance",
                      dataIndex: "similarity_category",
                      key: "similarity_category",
                      sorter: (a, b) =>
                        a.similarity_category - b.similarity_category,
                    },
                    {
                      title: "Tags Distance",
                      dataIndex: "similarity_tag",
                      key: "similarity_tag",
                      sorter: (a, b) => a.similarity_tag - b.similarity_tag,
                    },
                    {
                      title: "Common Tags",
                      dataIndex: ["common_tags"],
                      key: "product_common_tags",
                      render: (options) => (
                        <div style={{ maxWidth: 400 }}>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {options.map((option) => (
                              <Tag className="custom-tag" style={{ margin: 4 }} key={option}>
                                {option}
                              </Tag>
                            ))}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  dataSource={mostSimilarProducts}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Content>
  );
};

export default ProductsDashboard;
