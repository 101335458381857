import React from "react";
import { Button, Card, Form, Input, Typography, Row, Col } from "antd";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Title } = Typography;

const SignIn = ({ setIsLoggedIn }) => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleFinish = async (values) => {
    try {
      await login(values);
      navigate('/');
    } catch (e) {
      alert(e);
    }
  };

  return (
    <StyledRow justify="center" align="middle">
      <Col>
        <StyledLogoContainer>
          <StyledLogo src="/assets/logo-dark-text" alt="App Logo" />
        </StyledLogoContainer>
        <StyledCard>
          <Form onFinish={handleFinish} layout="vertical">
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <StyledInput type="email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <StyledInput type="password" />
            </Form.Item>
            <StyledButton type="primary" htmlType="submit">
              Sign In
            </StyledButton>
          </Form>
        </StyledCard>
      </Col>
    </StyledRow>
  );
};

export default SignIn;

const StyledRow = styled(Row)`
  height: 100vh;
  background-color: #f0f2f5;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledLogo = styled.img`
  height: 120px; /* Increased the height to make the logo bigger */
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  color: #0044cc;
  text-align: center;
  margin: 0;
`;

const StyledCard = styled(Card)`
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 8px #f0f1f2;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  background-color: #23AC68;
  border-color: #23AC68;
  &:hover {
    background-color: #1e8c56;
    border-color: #1e8c56;
  }
`;
