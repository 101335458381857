import React, { useState, useRef, useEffect } from 'react';
import {
  AutoComplete,
  Input,
  Card,
  Col,
  Form,
  Row,
  Spin,
  Typography,
  ConfigProvider
} from "antd";
import { SearchOutlined, BarsOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Tree } from "react-arborist";
import { getCategoriesTree } from '../../services';
import "../../styles/CategoryTree.css"; // Import custom CSS for styling

const { Title } = Typography;

function Node({ node, style, dragHandle }) {
  return (
    <div style={style} ref={dragHandle} className="tree-node">
      {node.children.length === 0 ? <ArrowRightOutlined /> : <BarsOutlined />}
      {node.children.length === 0 ? (
        node.data.name
      ) : (
        <strong>{node.data.name}</strong>
      )}
    </div>
  );
}

const CategoryTree = ({ refreshKey }) => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const inputRef = useRef();

  async function getDataSet() {
    setIsLoading(true);
    getCategoriesTree().then((data) => {
      setCategories(data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getDataSet();
  }, [refreshKey]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  const treeHeight = window.innerHeight - 150; // Calculate height as a number

  return (
    <Row gutter={[16, 16]} style={{ padding: '20px', height: '100vh', overflow: 'hidden' }}>
      <Col span={24}>
        <Card>
          <Form layout="vertical">
            <Form.Item label="Search Category">
              <AutoComplete
                popupClassName="category-dropdown"
                onSelect={(value, option) => setSearchTerm(option.label)}
                onSearch={(value) => setSearchTerm(value)}
                defaultValue=""
              >
                <Input
                  ref={inputRef}
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ color: "#DBE6DB" }} />}
                  style={{ width: '100%', borderRadius: '8px', padding: '10px' }}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={24} style={{ height: 'calc(100vh - 150px)', overflow: 'auto' }}>
        <Card style={{ height: '100%', overflow: 'hidden' }}>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <Tree
              data={categories}
              searchTerm={searchTerm}
              searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
              width={'100%'}
              height={treeHeight} // Use calculated height as a number
              indent={24}
              rowHeight={24}
              overscanCount={1}
              paddingTop={30}
              paddingBottom={10}
              padding={25}
              className="category-tree"
            >
              {Node}
            </Tree>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default CategoryTree;
