import React, { useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { currentUser } = useAuth(null)
    if (!currentUser) {
        return <Navigate to="/signin" replace />;
    }

    return children;
};

export default ProtectedRoute;