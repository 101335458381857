import { Card, Col, Flex, Form, Row, Select, Tag, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { searchProductsApi, getAllBrands, getAIProductInfoApi, getAIBrandInfoApi, getProductInfoApi, getBrandInfoApi } from "../../services";

const { TabPane } = Tabs;

const AIAutoFillDashboard = ({ refreshKey }) => {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [productDetails, setProductDetails] = useState();
  const [brandDetails, setBrandDetails] = useState();
  const [aiProductInfo, setAiProductInfo] = useState([]);
  const [aiBrandInfo, setAiBrandInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const brandOptions = brands.map((brand) => ({
    key: brand.id,
    value: brand.id,
    label: `${brand.name}`,
    originalBrand: { ...brand },
  }));

  async function getAiInfoForProduct() {
    setIsLoading(true);
    if (selectedProduct) {
      const aiInfo = await getAIProductInfoApi({ productKey: selectedProduct.key });

      let countryNames = aiInfo.countries || [];

      if (typeof countryNames === 'string') {
        countryNames = countryNames.split(',').map(name => name.trim());
      } else if (!Array.isArray(countryNames)) {
        countryNames = [];
      }
      aiInfo.countries = countryNames;
      setAiProductInfo(aiInfo);
    }
    setIsLoading(false);
  }

  async function getInfoForProduct() {
    if (selectedProduct) {
      const productDetails = await getProductInfoApi({ productKey: selectedProduct.key });
      setProductDetails(productDetails);
    }
  }

  async function getAiInfoForBrand() {
    setIsLoading(true);
    if (selectedBrand) {
      const aiInfo = await getAIBrandInfoApi({ brandKey: selectedBrand.key });
      setAiBrandInfo(aiInfo);
    }
    setIsLoading(false);
  }

  async function getInfoForBrand() {
    if (selectedBrand) {
      const brandDetails = await getBrandInfoApi({ brandKey: selectedBrand.key });
      setBrandDetails(brandDetails);
    }
  }

  async function fetchAllBrands() {
    const brands = await getAllBrands();
    setBrands(brands);
  }

  async function handleProductSearch(value) {
    setIsLoading(true);
    if (value) {
      const products = await searchProductsApi({ text: value });
      setProducts(products);
    } else {
      setProducts([]); // Clear products if search input is empty
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAllBrands();
    handleProductSearch("a");  // Initial search for products starting with "a"
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      getInfoForProduct();
      getAiInfoForProduct();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedBrand) {
      getInfoForBrand();
      getAiInfoForBrand();
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Products" key="1">
          <Row>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item 
                  label="Product"
                  help="Please select a product to view details."  // Descriptive text under the filter
                >
                  <Select
                    options={products.map((product) => ({
                      key: product.id,
                      value: product.id,
                      label: `${product.name}`,
                      originalProduct: { ...product },
                    }))}
                    onSearch={handleProductSearch}
                    onChange={(value, option) => {
                      setSelectedProduct(option.originalProduct);
                    }}
                    style={{ maxWidth: 500 }}
                    showSearch
                    filterOption={false} // Disable built-in filtering to rely on server-side search
                    loading={products.length === 0 && isLoading}
                    notFoundContent={isLoading ? <Spin size="small" /> : "No products found"}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div>
                <Title level={5}>When to use?</Title>
                <Card>
                  <Flex align="center" wrap="wrap">
                    In this case, the AI takes the product name from us and starts searching using just that name, without taking any data from our website (Badayli). Therefore, its accuracy is somewhat lower. We use this scenario when we don't know anything about the product and need hints about the tags or categories. These are just hints, and we still need to conduct further research because, no matter what, the AI is not always correct.
                  </Flex>
                  <Flex align="center" wrap="wrap">
                    في الحالة دي، الذكاء الاصطناعي بياخد اسم المنتج من عندنا وبيبدأ يعمل بحث بالاسم ده بس، من غير ما ياخد أي بيانات من موقعنا (بدايلي). عشان كده، الدقة بتاعته بتكون ضعيفة شوية. بنستخدم الحالة دي لما نكون مش عارفين أي حاجة عن المنتج وعايزين تلميحات عن الـ Tags أو الـ Categories. يعني هي مجرد تلميحات، وبرضه لازم نعمل بحث بعد كده لأن مهما كان، الذكاء الاصطناعي مش دايمًا بيكون صحيح.
                    بمعنى أصح:
                    بنستخدمها عشان نعرف تلميحات عن الـ Tags أو الـ Categories والـ Brand Owner.
                  </Flex>
                </Card>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <Spin />
          ) : !productDetails ? (
            <>
              <div>Please select a product to view details.</div>
            </>
          ) : (
            <>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product Name</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.name}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Name</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.product_name}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product Name AR</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.name_ar}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Name AR</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.product_name_ar}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product Desc</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.desc}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Desc</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.product_desc}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product Desc AR</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.desc_ar}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Desc AR</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.product_desc_ar}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product Brand Owner</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.brand.manufacturer.name}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Brand Owner</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.manufacturer}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>Product Brand</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.brand.name}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Brand</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.brand}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product Category</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.category.name}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Category</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.category}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product Tags</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.tags.map((tag) => {
                          return (
                            <Tag className="custom-tag" style={{ margin: 4 }} key={tag.id}>
                              {tag.name}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product AI Tags</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.tags.map((tag) => {
                          return (
                            <Tag className="custom-tag" style={{ margin: 4 }} key={tag}>
                              {tag}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Product Countries</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {productDetails.brand.countries.map((country) => {
                          return (
                            <Tag style={{ margin: 4 }} key={country}>
                              {country.name}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>AI Analyzed Countries</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiProductInfo.countries.map((country) => {
                          return (
                            <Tag style={{ margin: 4 }} key={country}>
                              {country}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </TabPane>
        <TabPane tab="Brands" key="2">
          <Row>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Brand">
                  <Select
                    options={brandOptions}
                    onChange={(value, option) => {
                      setSelectedBrand(option.originalBrand);
                    }}
                    style={{ maxWidth: 500 }}
                    showSearch
                    filterOption={(value, option) => {
                      return option.label.toLowerCase().includes(value.toLowerCase());
                    }}
                    loading={brands.length === 0}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div>
                <Title level={5}>When to use?</Title>
                <Card>
                  <Flex align="center" wrap="wrap">
                    In this case, the AI takes the brand name and uses data from us, such as (brand name, products from the same brand, owner, tags, etc.). Its accuracy is much higher, and we can rely on it more. We use this scenario when we are unsure between multiple manufacturers, so we add them to our database, select the brand, and the AI searches and tells us which one is correct. However, we still need to conduct further research because, no matter what, it is not always correct. We also use it to find out which countries these brands are available in, and so on.
                  </Flex>
                  <Flex align="center" wrap="wrap">
                    في الحالة دي، الذكاء الاصطناعي بياخد اسم البراند وبيستخدم بيانات من عندنا زي (اسم البراند، منتجات من نفس البراند، المصنع، الـ Tags، وهكذا). الدقة بتاعته بتكون أعلى بكتير، وممكن نعتمد عليه أكتر. بنستخدم الحالة دي لو احنا مش متأكدين بين أكتر من مصنع (manufacturer)، فبنضيفهم عندنا ونختار البراند، والذكاء الاصطناعي بيسرش وبيقول مين الصح. بس برضه لازم نعمل بحث لأن مهما كان، مش دايمًا بيكون صحيح. بنستخدمها كمان عشان ندور على البلاد اللي البراندات دي بتكون موجودة فيها وهكذا.
                    بمعنى أصح:
                    بتكون أدق لأنها بتعتمد على بيانات أكتر وبتختار الـ Brand Owner من عندنا في الداتا بيس.
                  </Flex>
                </Card>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <Spin />
          ) : !brandDetails ? (
            <>
              <div>Please select a brand to view details.</div>
            </>
          ) : (
            <>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <div>
                    <Title level={5}>Brand Owner</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {brandDetails.manufacturer.name}
                      </Flex>
                    </Card>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <Title level={5}>AI Analyzed Brand Owner</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiBrandInfo.manufacturer}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>Brand Countries</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {brandDetails.countries.map((country) => {
                          return (
                            <Tag style={{ margin: 4 }} key={country}>
                              {country.name}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Title level={5}>AI Analyzed Countries</Title>
                    <Card>
                      <Flex align="center" wrap="wrap">
                        {aiBrandInfo.countries.map((country) => {
                          return (
                            <Tag style={{ margin: 4 }} key={country}>
                              {country}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Card>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AIAutoFillDashboard;
