import { sortBy } from "lodash";
import uniqBy from "lodash/uniqBy";

const BASE_URL = "/api/v1";
// const BASE_URL = "https://labs.badayli.com/api/v1";

export const getToken = () => {
  return localStorage.getItem("authToken") || "";
};

export const login = async ({ email, password }) => {
  const results = {
    data: null,
    error: null,
  };
  try {
    const response = await http.post("/users/sign_in", {
      user: {
        email,
        password,
      },
    });
    const authToken = response.token; // Assuming token is in the headers
    if (authToken) {
      localStorage.setItem("authToken", authToken); // Store token in localStorage
      localStorage.setItem("currentUser", JSON.stringify(response.user)); // Store current user in localStorage
      results.data = response.user;
      console.log("Sign in successful", results.data);
    } else {
      results.error = response.errors[0];
      alert(response.errors[0]);
      console.error("Sign in error", response);
    }
    // Handle success, store the auth tokens, navigate to another page, etc.
  } catch (error) {
    alert(error);
    results.error = error;
    console.error("Sign in error", error.response || error);
    // Handle error
  }

  return results;
};

export const getMostSimilarProductsApi = async ({
  productKey,
  threshold = 60,
}) => {
  // &force_actual to disable any cache through the system

  const products = await http.get(
    `/products/most_similar?product=${encodeURIComponent(
      productKey
    )}&threshold=${threshold}`
  );
  return uniqBy(products, (p) => p.similar_product.id);
};
export const getMostSimilarProductNames = async () => {
  const products = await http.get(`/products/most_similar_names`);
  return sortBy(products, (product) => product.similarity_name).reverse();
};
export const searchProductsApi = async ({
  text = "",
  category = "",
  manufacturer = "",
  brand = "",
  tags = [],
}) => {
  // &force_actual to disable any cache through the system
  const products = await http.get(
    `/products/search?text=${text}&category=${encodeURIComponent(
      category
    )}&manufacturer=${encodeURIComponent(
      manufacturer
    )}&brand=${encodeURIComponent(brand)}&tags[]=${tags}`
  );
  return products;
};

export const getAllBrands = async () => {
  return http.get(`/brands`);
};
export const getAllProducts = async () => {
  return http.get(`/products`);
};
export const getUniqueProducts = async () => {
  return http.get(`/products/unique`);
};
export const getUniqueManufacturers = async () => {
  return http.get(`/manufacturers/unique`);
};

export const getMostSimilarTags = async () => {
  const tags = await http.get(`/tags/most_similar`);
  return sortBy(tags, (tag) => tag.similarity).reverse();
};

export const getMostSimilarManufactrurers = async () => {
  const manufacturers = await http.get(`/manufacturers/most_similar`);
  return sortBy(
    manufacturers,
    (manufacturer) => manufacturer.similarity
  ).reverse();
};

export const getMostSimilarBrands = async () => {
  const brands = await http.get(`/brands/most_similar`);
  return sortBy(brands, (brand) => brand.similarity).reverse();
};

export const getLogs = async () => {
  return http.get(`/logs`);
};

export const getTestCases = async () => {
  return http.get(`/test_cases`);
};

export const getUniqueTags = async () => {
  return http.get("/tags/unique");
};

export const getUniqueCategories = async () => {
  return http.get("/categories/unique");
};
export const getCategoriesTree = async () => {
  return http.get("/categories/tree");
};

export const getUniqueBrands = async () => {
  return http.get("/brands/unique");
};

export const getProductsReports = async () => {
  return http.get("/reports/products");
};
export const getBrandsReports = async () => {
  return http.get("/reports/brands");
};
export const getCategoriesReports = async () => {
  return http.get("/reports/categories");
};
export const getCountriesReports = async () => {
  return http.get("/reports/countries");
};
export const getTagsReports = async () => {
  return http.get("/reports/tags");
};
export const getManufacturerReports = async () => {
  return http.get("/reports/manufacturers");
};
export const getTestCasesReports = async () => {
  return http.get("/reports/test_cases");
};

export const getAIProductInfoApi = async ({
  productKey
}) => {
  const info = await http.get(
    `/products/ai_info?product=${encodeURIComponent(
      productKey
    )}`
  );
  return info;
};
export const getAIBrandInfoApi = async ({
  brandKey
}) => {
  const info = await http.get(
    `/brands/ai_info?brand=${encodeURIComponent(
      brandKey
    )}`
  );
  return info;
};
export const getProductInfoApi = async ({
  productKey
}) => {
  const info = await http.get(
    `/products/by_key/${encodeURIComponent(
      productKey
    )}`
  );
  return info;
};
export const getBrandInfoApi = async ({
  brandKey
}) => {
  const info = await http.get(
    `/brands/by_key/${encodeURIComponent(
      brandKey
    )}`
  );
  return info;
};

export const logOut = async () => {
  const resp = await http.delete("/users/sign_out");
  localStorage.removeItem("authToken");
  localStorage.removeItem("currentUser");
  return resp;
};

// #TODO
// Add Interceptor to all methods

const http = {
  post: async (endpoint, body) => {
    const options = {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    let url = BASE_URL + endpoint;
    if (url.indexOf("sign_in") === -1) {
      options.headers["Authorization"] = getToken();
    } else {
      url = url.replaceAll("api/v1/", "");
    }
    const resp = await fetch(url, options);
    const json = await resp.json();

    const authToken = resp.headers.get("Authorization");
    if (authToken) {
      json.token = authToken;
    }
    return json;
  },

  get: async (endpoint) => {
    const resp = await fetch(BASE_URL + endpoint, {
      headers: {
        Authorization: getToken(),
      },
    });
    const json = await resp.json();
    if (resp.status == 422 || resp.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("currentUser");
      window.location.href = "/";
    }
    return json;
  },
  delete: async (endpoint) => {
    let url = BASE_URL + endpoint;

    if (url.indexOf("sign_out") !== -1) {
      url = url.replaceAll("api/v1/", "");
    }

    const resp = await fetch(url, {
      method: "delete",
      headers: {
        Authorization: getToken(),
      },
    });
    const json = await resp.json();
    return json;
  },
};
