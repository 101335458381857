import React from "react";
import { createRoot } from 'react-dom/client';

import SignIn from "../components/SignIn";
import App from "../components/App";
// import App from "../components/App";

const container = document.getElementById('root');
const root = createRoot(container); // Create a root
root.render(<App />);

// document.addEventListener("DOMContentLoaded", () => {
//   ReactDOM.render(<App />, document.getElementById("root"));
// });
