import React, { useEffect, createContext, useContext, useState } from 'react';
import { login as login_service, logOut as logout_service } from '../services'
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const savedCurrentUser = localStorage.getItem("currentUser");
  const [currentUser, setCurrentUser] = useState(savedCurrentUser ? JSON.parse(savedCurrentUser) : null);

  // #TODO
  // useEffect(() => {
  //   const token = localStorage.getItem('authToken')
  //   if (token) {
  //     setCurrentUser( { email: "admin@example.com" } )
  //   }
  // },[])

  // Simulate a login function
  const login = async (userCredentials) => {
    const response = await login_service(userCredentials)

    if (response.data) {
      setCurrentUser(response.data);
    }
    else if (response.error) {
      console.error(response.error)
    }
  };

  // Simulate a logout function
  const logOut = async () => {
    const resp = await logout_service()
    setCurrentUser(null);
    navigate("/");
  };

  const value = {
    currentUser,
    login,
    logOut
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};