import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  AutoComplete,
  Input,
  Card,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag,
  Spin,
  Typography
} from "antd";
import {
  getUniqueBrands,
  getUniqueCategories,
  getUniqueManufacturers,
  getUniqueTags,
  searchProductsApi,
} from "../../services";
import { SearchOutlined } from "@ant-design/icons";
import "../../styles/Search.css"; // Import custom CSS for styling

const { Title } = Typography;

const Search = ({ refreshKey }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchText, setSearchText] = useState("a");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchManufacturer, setSearchManufacturer] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [searchBrand, setSearchBrand] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef();

  const fetchInitialData = useCallback(async () => {
    try {
      setIsLoading(true);
      const categories = await getUniqueCategories();
      const manufacturers = await getUniqueManufacturers();
      const brands = await getUniqueBrands();
      const tags = await getUniqueTags();
      
      setCategories(categories);
      setManufacturers(manufacturers);
      setBrands(brands);
      setTags(tags);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, []);

  const fetchProducts = useCallback(async (searchText, category, manufacturer, brand, tags) => {
    setIsLoading(true);
    const searchedProducts = await searchProductsApi({
      text: searchText,
      category: category || "",
      manufacturer: manufacturer || "",
      brand: brand || "",
      tags: tags || [],
    });
    setProducts(searchedProducts);
    setSearchedProducts(searchedProducts.map((p) => ({ ...p, key: p.id })));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchInitialData();
    fetchProducts("a");
  }, [fetchInitialData, fetchProducts]);

  const options = {
    products: products.map((product) => ({
      key: product.id,
      value: product.crappy_name,
      label: `${product.crappy_name}`,
      originalProduct: { ...product },
    })),
    categories: categories.map((category) => ({
      key: category.id,
      value: category.key,
      label: `${category.name}`,
      originalCategory: { ...category },
    })),
    brands: brands.map((brand) => ({
      key: brand.id,
      value: brand.key,
      label: `${brand.name}`,
      originalBrand: { ...brand },
    })),
    manufacturers: manufacturers.map((manufacturer) => ({
      key: manufacturer.id,
      value: manufacturer.id,
      label: `${manufacturer.name}`,
      originalManufacturer: { ...manufacturer },
    })),
  };

  useEffect(() => {
    if (refreshKey) {
      fetchProducts(searchText, searchCategory.key, searchManufacturer.key, searchBrand.key, searchTags.map(tag => tag.key));
    }
  }, [refreshKey, fetchProducts, searchText, searchCategory, searchManufacturer, searchBrand, searchTags]);

  const handleSearchTextChange = (value) => {
    setSearchText(value);
    fetchProducts(value, searchCategory.key, searchManufacturer.key, searchBrand.key, searchTags.map(tag => tag.key));
  };

  const handleSearchCategoryChange = (value, option) => {
    setSearchCategory(option.originalCategory);
    fetchProducts(searchText, option.originalCategory.key, searchManufacturer.key, searchBrand.key, searchTags.map(tag => tag.key));
  };

  const handleSearchManufacturerChange = (value, option) => {
    setSearchManufacturer(option.originalManufacturer);
    fetchProducts(searchText, searchCategory.key, option.originalManufacturer.key, searchBrand.key, searchTags.map(tag => tag.key));
  };

  const handleSearchBrandChange = (value, option) => {
    setSearchBrand(option.originalBrand);
    fetchProducts(searchText, searchCategory.key, searchManufacturer.key, option.originalBrand.key, searchTags.map(tag => tag.key));
  };

  return (
    <div>
        <Card style={{ marginBottom: 24 }}>
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item label="Product">
                  <AutoComplete
                    options={options.products}
                    popupClassName="products-dropdown"
                    onSelect={(value, option) => {
                      setSearchText(option.label);
                      fetchProducts(option.label, searchCategory.key, searchManufacturer.key, searchBrand.key, searchTags.map(tag => tag.key));
                    }}
                    onSearch={handleSearchTextChange}
                    value={searchText}
                  >
                    <Input
                      ref={inputRef}
                      placeholder="Search"
                      prefix={<SearchOutlined style={{ color: "#DBE6DB" }} />}
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Category">
                  <Select
                    options={options.categories}
                    onChange={handleSearchCategoryChange}
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    loading={categories.length === 0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Brand Owner">
                  <Select
                    options={options.manufacturers}
                    onChange={handleSearchManufacturerChange}
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    loading={manufacturers.length === 0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Brand">
                  <Select
                    options={options.brands}
                    onChange={handleSearchBrandChange}
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    loading={brands.length === 0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoading ? (
          <Spin />
        ) : (
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Title level={5}>Badayel</Title>
              <Card>
                <Table
                  key={Date.now()}
                  pagination={{ pageSize: 1000 }}
                  columns={[
                    {
                      title: "Crappy Name",
                      dataIndex: ["crappy_name"],
                      key: "crappy_name",
                    },
                    {
                      title: "Name",
                      dataIndex: ["name"],
                      key: "product_name",
                    },
                    {
                      title: "Badayels",
                      dataIndex: ["badayel_products_count"],
                      key: "badayel_products_count",
                      sorter: (a, b) =>
                        a.badayel_products_count - b.badayel_products_count,
                    },
                    {
                      title: "Category",
                      dataIndex: ["category_key"],
                      key: "category_key",
                    },
                    {
                      title: "Tags",
                      dataIndex: ["tags"],
                      key: "tags",
                      render: (options) => (
                        <div style={{ maxWidth: 400 }}>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {options.map((option) => (
                              <Tag className="custom-tag" key={option.id}>
                                {option.name}
                              </Tag>
                            ))}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  dataSource={searchedProducts}
                  className="custom-table" // Add custom class for styling
                />
              </Card>
            </Col>
          </Row>
        )}
      </div>
  );
};

export default Search;
