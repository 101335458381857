import { Card, Col, Descriptions, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import {
  getProductsReports,
  getCategoriesReports,
  getTagsReports,
  getBrandsReports,
  getManufacturerReports,
  getTestCasesReports,
  getCountriesReports
} from "../../services";

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ReportsDashboard = ({ refreshKey }) => {
  const [reports, setReports] = useState();

  const [productsDescriptions, setProductsDescriptions] = useState([]);
  const [testCasesDescriptions, setTestCasesDescriptions] = useState([]);
  const [brandsDescriptions, setBrandsDescriptions] = useState([]);
  const [categoriesDescriptions, setCategoriesDescriptions] = useState([]);
  const [manufacturersDescriptions, setManufacturersDescriptions] = useState([]);
  const [tagsDescriptions, setTagsDescriptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  async function getReports() {
    setIsLoading(true);
    const reports = {
      products: await getProductsReports(),
      test_cases: await getTestCasesReports(),
      brands: await getBrandsReports(),
      tags: await getTagsReports(),
      categories: await getCategoriesReports(),
      manufacturers: await getManufacturerReports(),
      countries: await getCountriesReports()
    };
    setIsLoading(false);
    setReports(reports);
  }

  const getValue = (type, key) => {
    return reports && reports[type] && reports[type][key] ? reports[type][key] : 0;
  };

  useEffect(() => {
    getReports();
    const interval = setInterval(getReports, 1000 * 60 * 5); // 5 minutes refresh rate
    return () => clearInterval(interval);
  }, [refreshKey]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    setProductsDescriptions([
      { key: "all", label: "All", children: getValue("products", "all") },
      { key: "published", label: "Published", children: getValue("products", "published") },
      { key: "draft", label: "Draft", children: getValue("products", "draft") },
      { key: "scan_progress", label: "Scan Progress", children: getValue("products", "scan_progress") },
      { key: "without_badayel", label: "Without Any Badeel", children: getValue("products", "without_badayel") },
      { key: "badayel_3", label: "<= 3 Badayels", children: getValue("products", "badayel_3") },
      { key: "badayel_5", label: "<= 5 Badayels", children: getValue("products", "badayel_5") },
      { key: "badayel_10", label: "<= 10 Badayels", children: getValue("products", "badayel_10") },
      { key: "badayel_10a", label: "> 10 Badayels", children: getValue("products", "badayel_10a") },
      { key: "quality", label: "Quality (%)", children: getValue("products", "quality") }
    ]);

    setTestCasesDescriptions([
      { key: "all", label: "All", children: getValue("test_cases", "all") },
      { key: "passed", label: "Passed", children: getValue("test_cases", "passed") },
      { key: "failed", label: "Failed", children: getValue("test_cases", "failed") },
      { key: "missing_product", label: "Missing Products", children: getValue("test_cases", "missing_product") },
      { key: "quality", label: "Quality (%)", children: getValue("test_cases", "quality") }
    ]);

    setBrandsDescriptions([
      { key: "all", label: "All", children: getValue("brands", "all") },
      { key: "valid", label: "Valid", children: getValue("brands", "valid") },
      { key: "missing_arabic", label: "Missing Arabic", children: getValue("brands", "missing_arabic") },
      { key: "no_or_dummy_or_orphaned_manufacturer", label: "Bad Brand Owners", children: getValue("brands", "no_or_dummy_or_orphaned_manufacturer") }
    ]);

    setTagsDescriptions([
      { key: "all", label: "All", children: getValue("tags", "all") },
      { key: "quality", label: "Quality", children: getValue("tags", "quality") },
      { key: "high_used_tags", label: "Highly Used Tags", children: getValue("tags", "high_used_tags") },
      { key: "moderate_used_tags", label: "Moderate Used Tags", children: getValue("tags", "moderate_used_tags") },
      { key: "low_used_tags", label: "Low Used Tags", children: getValue("tags", "low_used_tags") },
      { key: "rare_used_tags", label: "Rarely Used Tags", children: getValue("tags", "rare_used_tags") },
      { key: "zero_used_tags", label: "Not Used Tags", children: getValue("tags", "zero_used_tags") }
    ]);

    setManufacturersDescriptions([
      { key: "all", label: "All", children: getValue("manufacturers", "all") },
      { key: "missing_countries", label: "Missing Countries", children: getValue("manufacturers", "missing_countries") },
      { key: "missing_arabic", label: "Missing Arabic", children: getValue("manufacturers", "missing_arabic") }
    ]);

    setCategoriesDescriptions([
      { key: "all", label: "All", children: getValue("categories", "all") },
      { key: "missing_arabic", label: "Missing Arabic", children: getValue("categories", "missing_arabic") }
    ]);
  }, [reports]);

  // Columns for the table
  const countriesTablecolumns = [
    { title: 'Country Name', dataIndex: 'country_name', key: 'country_name' },
    { title: 'Brands Coverage (%)', dataIndex: 'brands_coverage', key: 'brands_coverage' },
    { title: 'Brands Count', dataIndex: 'brands_count', key: 'brands_count' },
    { title: 'Brand Owners Count', dataIndex: 'manufacturers_count', key: 'manufacturers_count' },
    { title: 'Products Coverage (%)', dataIndex: 'products_coverage', key: 'products_coverage' },
    { title: 'Products Count', dataIndex: 'products_count', key: 'products_count' }
  ];

  // Data for the charts
  const productsQualityData = {
    labels: [
      "Without Any Badeel",
      "<= 3 Badayels",
      "<= 5 Badayels",
      "<= 10 Badayels",
      "> 10 Badayels"
    ],
    datasets: [
      {
        label: "Products Quality",
        data: [
          getValue("products", "without_badayel"),
          getValue("products", "badayel_3"),
          getValue("products", "badayel_5"),
          getValue("products", "badayel_10"),
          getValue("products", "badayel_10a")
        ],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF"
        ],
      },
    ],
  };

  const testCasesData = {
    labels: ["Passed", "Failed", "Missing Products"],
    datasets: [
      {
        label: "Test Cases",
        data: [
          getValue("test_cases", "passed"),
          getValue("test_cases", "failed"),
          getValue("test_cases", "missing_product")
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const brandsData = {
    labels: ["Valid", "Without Manufacturer"],
    datasets: [
      {
        label: "Brands",
        data: [getValue("brands", "valid"), getValue("brands", "no_or_dummy_or_orphaned_manufacturer")],
        backgroundColor: ["#36A2EB", "#FFCE56"],
      },
    ],
  };

  const manufacturersData = {
    labels: ["All", "Missing Countries", "Missing Arabic"],
    datasets: [
      {
        label: "Brand Owners",
        data: [
          getValue("manufacturers", "all"),
          getValue("manufacturers", "missing_countries"),
          getValue("manufacturers", "missing_arabic")
        ],
        backgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"],
      },
    ],
  };

  const tagsData = {
    labels: [
      "Highly Used Tags",
      "Moderate Used Tags",
      "Low Used Tags",
      "Rarely Used Tags",
      "Not Used Tags"
    ],
    datasets: [
      {
        label: "Tags",
        data: [
          getValue("tags", "high_used_tags"),
          getValue("tags", "moderate_used_tags"),
          getValue("tags", "low_used_tags"),
          getValue("tags", "rare_used_tags"),
          getValue("tags", "zero_used_tags")
        ],
        backgroundColor: [
          "#36A2EB",
          "#FFCE56",
          "#FF6384",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#C9CBCF"
        ],
      },
    ],
  };

  const categoriesData = {
    labels: ["All", "Missing Arabic"],
    datasets: [
      {
        label: "Categories",
        data: [getValue("categories", "all"), getValue("categories", "missing_arabic")],
        backgroundColor: ["#36A2EB", "#FFCE56"],
      },
    ],
  };

  // Sort countries by a chosen metric, for example 'brands_coverage'
  const sortedCountries = reports && reports.countries 
    ? reports.countries.sort((a, b) => b.brands_coverage - a.brands_coverage).slice(0, 25) 
    : [];

  const countriesData = {
    labels: sortedCountries.map(country => country.country_name),
    datasets: [
      {
        label: "Brands Coverage (%)",
        data: sortedCountries.map(country => country.brands_coverage),
        backgroundColor: "#36A2EB",
      },
      {
        label: "Products Coverage (%)",
        data: sortedCountries.map(country => country.products_coverage),
        backgroundColor: "#FFCE56",
      }
    ]
  };

  return (
    <>
      {isLoading && !reports ? (
        <Spin />
      ) : (
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Card>
              <div>
                Quality numbers are the percentage of products have badayls to the total number of products.
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Descriptions title="Products Summary" items={productsDescriptions} />
              <div style={{ height: '200px' }}>
                <Bar data={productsQualityData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card>
              <Descriptions title="Test Cases Summary" items={testCasesDescriptions} />
              <div style={{ height: '200px' }}>
                <Pie data={testCasesData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card>
              <Descriptions title="Brands Summary" items={brandsDescriptions} />
              <div style={{ height: '200px' }}>
                <Doughnut data={brandsData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card>
              <Descriptions title="Brand Owners Summary" items={manufacturersDescriptions} />
              <div style={{ height: '200px' }}>
                <Pie data={manufacturersData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card>
              <Descriptions title="Tags Summary" items={tagsDescriptions} />
              <div style={{ height: '200px' }}>
                <Bar data={tagsData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card>
              <Descriptions title="Categories Summary" items={categoriesDescriptions} />
              <div style={{ height: '200px' }}>
                <Pie data={categoriesData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>

          <Col span={24}>
            <Card>
              <Table
                title={() => 'Countries Statistics'}
                dataSource={reports && reports.countries ? reports.countries : []}
                columns={countriesTablecolumns}
                rowKey="country_name"
                className="custom-table"
              />
              <div style={{ height: '400px', marginTop: '20px' }}>
                <Bar data={countriesData} options={{ maintainAspectRatio: false }} />
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReportsDashboard;
