import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const hints = {
  "1": [
    "Hello, I'm Farah. Welcome to the Overview! Here you can see reports and analytics.",
    "اهلا, انا فرح.  إحنا بنستخدم بدايلي علشان نقدر نعمل تيست للداتا اللي عندنا، يعني بنحط اسم المنتج اللي عندنا، وهو بيخرجلنا البدايل له على حسب الـ Tags و Category وهكذا"
  ],
  "2": [
    "Explore our products in the Badayli section.",
    "Discover new items in Badayli."
  ],
  "3": [
    "Use the Search feature to find what you need.",
    "Looking for something specific? Try the Search feature."
  ],
  "4": [
    "Navigate the Categories Tree to browse our categories.",
    "Find products by browsing the Categories Tree."
  ],
  "5": [
    "Check out the AI Auto Fill feature for automated suggestions.",
    "Let AI Auto Fill help you with smart suggestions."
  ]
};

const CharacterHint = ({ activeTabKey }) => {
  const [currentHintIndex, setCurrentHintIndex] = useState(0);

  useEffect(() => {
    setCurrentHintIndex(0); // Reset hint index when tab changes
  }, [activeTabKey]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentHintIndex((prevIndex) => (prevIndex + 1) % hints[activeTabKey].length);
    }, 5000); // Change hint every 5 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [activeTabKey]);

  return (
    <CharacterContainer>
      <SpeechBubble>
        {hints[activeTabKey][currentHintIndex]}
        <p></p>
        <HintButton onClick={() => setCurrentHintIndex((prevIndex) => (prevIndex + 1) % hints[activeTabKey].length)}>Next Hint</HintButton>
      </SpeechBubble>
      <Character>
        <img src="/assets/cartoon-character.png" alt="Badayli Hero" />
      </Character>
    </CharacterContainer>
  );
};

export default CharacterHint;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const CharacterContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  animation: ${fadeIn} 1s ease-in-out, ${float} 3s ease-in-out infinite;
`;

const Character = styled.div`
  width: 150px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const SpeechBubble = styled.div`
  background-color: #fff;
  border: 2px solid #23AC68;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  position: relative;
  max-width: 200px;
  color: #414241;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid #23AC68;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
`;

const HintButton = styled.button`
  background-color: #23AC68;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    background-color: #1e8c56;
  }
`;
